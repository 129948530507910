import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"

const Work = props => {
  const post = props.data.markdownRemark
  const frontmatter = post.frontmatter
  const next = props.pageContext.next
  const prev = props.pageContext.previous

  return (
    <Layout>
      <Seo title={frontmatter.client + " / " + frontmatter.title} />
      <div>
        <h1
          className={`font-thin font-sans uppercase text-3xl tracking-wide py-2`}
        >
          {frontmatter.client} / {frontmatter.title}
        </h1>
        <div className={`sm:flex`}>
          <div className={`sm:w-2/3`}>
            <div
              id="video-player"
              className="relative w-full h-0 bg-black"
              style={{ paddingBottom: "56.25%" }}
            >
              <iframe
                title={frontmatter.title}
                className="absolute w-full h-full left-0 top-0"
                src={`https://player.vimeo.com/video/${frontmatter.vimeoId}?autoplay=1`}
                width="640"
                height="335"
                allowFullScreen
              ></iframe>
            </div>

            {(next || prev) && (
              <div className="mt-4">
                <nav className={`flex`}>
                  {next && (
                    <p className={`w-1/2 flex-col`}>
                      <Link
                        to={`/${next.node.fields.uri}`}
                        className={`next`}
                        title={`Next Item: ${next.node.frontmatter.title}`}
                      >
                        ← “{next.node.frontmatter.title}”
                      </Link>
                    </p>
                  )}
                  {prev && (
                    <p
                      className={
                        `flex-col items-end content-end text-right ` +
                        (next ? `w-1/2` : `w-full`)
                      }
                    >
                      <Link
                        to={`/${prev.node.fields.uri}`}
                        className={`prev`}
                        title={`Next Item: ${prev.node.frontmatter.title}`}
                      >
                        “{prev.node.frontmatter.title}” →
                      </Link>
                    </p>
                  )}
                </nav>
              </div>
            )}
          </div>
          <div className={`my-8 sm:my-0 sm:w-1/3 sm:px-8`}>
            <dl>
              {frontmatter.client ? (
                <>
                  <dt className={`text-xs uppercase font-sans w-12`}>Client</dt>
                  <dd className={`mb-3`}>{frontmatter.client}</dd>
                </>
              ) : (
                ``
              )}
              {frontmatter.title ? (
                <>
                  <dt className={`text-xs uppercase font-sans`}>Spot Title</dt>
                  <dd className={`mb-3`}>“{frontmatter.title}”</dd>
                </>
              ) : (
                ``
              )}
              {frontmatter.agency ? (
                <>
                  <dt className={`text-xs uppercase font-sans`}>Agency</dt>
                  <dd className={`mb-3`}>{frontmatter.agency}</dd>
                </>
              ) : (
                ``
              )}
              {frontmatter.director ? (
                <>
                  <dt className={`text-xs uppercase font-sans`}>Director</dt>
                  <dd className={`mb-3`}>{frontmatter.director}</dd>
                </>
              ) : (
                ``
              )}
              {frontmatter.production ? (
                <>
                  <dt className={`text-xs uppercase font-sans`}>Production</dt>
                  <dd className={`mb-3`}>{frontmatter.production}</dd>
                </>
              ) : (
                ``
              )}
              {frontmatter.editorial ? (
                <>
                  <dt className={`text-xs uppercase font-sans`}>Editorial</dt>
                  <dd className={`mb-3`}>{frontmatter.editorial}</dd>
                </>
              ) : (
                ``
              )}
              {frontmatter.vfx ? (
                <>
                  <dt className={`text-xs uppercase font-sans`}>VFX</dt>
                  <dd className={`mb-3`}>{frontmatter.vfx}</dd>
                </>
              ) : (
                ``
              )}
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        client
        agency
        director
        production
        editorial
        vfx
        vimeoId
      }
      fields {
        collection
      }
    }
  }
`

export default Work
